import { useState } from "react";
import { Tooltip } from "antd";
import useGetUserInfo from "hooks/useGetUserInfo";
import Text from "components/general/Text";
import MarkVisit from "components/modals/MarkVisit";
import moment from "moment";
import useScreenSize from "hooks/useScreenSize";
import Image from "../../general/Image";
import Button from "../../general/Button";
import Icon from "../../general/Icon";
import { UpcomingVisitCardProps } from "./types";
import styles from "./styles.module.scss";

export default function UpcomingVisitCard({
  containerStyle,
  visit,
}: UpcomingVisitCardProps) {
  const { isClient } = useGetUserInfo();
  const [isVisible, setIsVisible] = useState(false);
  const { isMobile } = useScreenSize(0);

  return (
    <div className={`${styles.container} ${containerStyle}`}>
      {!isClient && (
        <div className={styles.locationAndPhoneBtns}>
          {visit.location && visit.location.lat && visit.location.lng && (
            <Button
              btnClassName={styles.btn}
              onClick={() =>
                window.location.replace(
                  `https://www.google.com/maps/search/?api=1&query=${visit?.location?.lat},${visit?.location?.lng}`
                )
              }
            >
              {isMobile ? (
                <div>
                  <Icon name="location" size={20} color="primary" />
                </div>
              ) : (
                <Tooltip title="View location on Google Maps">
                  <div>
                    <Icon name="location" size={20} color="primary" />
                  </div>
                </Tooltip>
              )}
            </Button>
          )}
          {visit.location && visit.location.phone && (
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            <a className={styles.btn} href={`tel:${visit?.location?.phone}`}>
              {isMobile ? (
                <div>
                  <Icon name="phone" size={20} color="primary" />
                </div>
              ) : (
                <Tooltip title="Call location number">
                  <div>
                    <Icon name="phone" size={20} color="primary" />
                  </div>
                </Tooltip>
              )}
            </a>
          )}
        </div>
      )}

      {isClient && visit.number && (
        <div className={styles.visitNumHeader}>
          <Text className={styles.title}>Visit Number:</Text>
          <Text className={styles.title}>#{visit?.number}</Text>
        </div>
      )}

      {!isClient && (
        <div className={styles.companyLogoAndTitle}>
          {visit?.location?.project?.company_logo && (
            <div className={styles.logoContainer}>
              <Image
                src={visit?.location?.project?.company_logo}
                alt="company-logo"
              />
            </div>
          )}
          <Text className={styles.title}>
            {visit?.location?.project?.company_name}
          </Text>
        </div>
      )}

      <div className={styles.visitInfo}>
        <div className={styles.infoRow}>
          <Text>Project:</Text>
          <Text>{visit?.location?.project?.name}</Text>
        </div>
        {!isClient && (
          <div className={styles.infoRow}>
            <Text>Order Number:</Text>
            <Text>#{visit.number}</Text>
          </div>
        )}
        <div className={styles.infoRow}>
          <Text>Date:</Text>
          <Text>
            {visit?.scheduled_date &&
              moment(visit?.scheduled_date).format("DD MMMM YYYY - hh:mm a")}
          </Text>
        </div>
        <div className={styles.infoRow}>
          <Text>Location:</Text>
          <Text>{visit?.location?.name}</Text>
        </div>
        {!isClient && (
          <div className={styles.infoRow}>
            <Text>Phone:</Text>
            <Text>{visit?.location?.phone}</Text>
          </div>
        )}
      </div>

      {!isClient && visit.on_complete_map_stamp === null && (
        <>
          <hr className={styles.divider} />
          <div className={styles.markBtnContainer}>
            <Button
              btnClassName={styles.markBtn}
              onClick={() => setIsVisible(true)}
            >
              Mark as Complete
            </Button>
          </div>
          <MarkVisit
            visitId={visit.id!}
            visitNumber={visit.number!}
            logo={visit.location!.project.company_logo!}
            clientName={visit.location!.project.company_name!}
            isVisible={isVisible}
            setIsVisible={setIsVisible}
            onConfirm={() => setIsVisible(false)}
          />
        </>
      )}
    </div>
  );
}
